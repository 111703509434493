<template>
    <div>
        <v-card tile class="search-card px-3 mb-3">
            <v-form
                autocomplete="off"
                @submit.prevent="getDataFromApi">
                <v-card-subtitle class="text-uppercase">
                    Wyszukiwarka
                </v-card-subtitle>
                <v-card-text class="pb-0">
                    <div class="py-0">
                        <v-row>
                            <v-col cols="12" md="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchDateFrom"
                                    label="Data od"
                                    v-mask="'XXXX-XX-XX XX:XX'"
                                    hint="Format: YYYY-MM-DD HH:MM"
                                    persistent-hint
                                    dense
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    aria-autocomplete="none"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" md="4" class="py-sm-1">
                                <v-text-field
                                    v-model="searchDateTo"
                                    label="Data do"
                                    v-mask="'XXXX-XX-XX XX:XX'"
                                    hint="Format: YYYY-MM-DD HH:MM"
                                    persistent-hint
                                    dense
                                    clearable
                                    prepend-icon="mdi-calendar-range"
                                    aria-autocomplete="none"
                                    outlined
                                />
                            </v-col>
                            <v-col cols="12" sm="4" class="py-sm-1">
                                <v-autocomplete
                                    label="Kierowca"
                                    :items="listDriver"
                                    v-model="driverID"
                                    clearable
                                    dense
                                    hide-details
                                    auto-select-first
                                    item-text="driver_number"
                                    prepend-icon="mdi-taxi"
                                    item-value="id"
                                    outlined
                                    @change="getDataFromApi"
                                >
                                    <template v-slot:selection="data">
                                        {{ data.item.driver_number }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{ data.item.driver_number }} - {{ data.item.first_name }} {{ data.item.last_name }}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <v-card-actions class="mt-2">
                    <v-btn x-small color="green darken-4" class="mr-1 white--text" @click="setDateRange('month-1')">Poprzedni miesiąc</v-btn>
                    <v-btn x-small color="green darken-4" class="mr-1 white--text" @click="setDateRange('month')">Aktualny miesiąc</v-btn>
                    <v-btn x-small color="green darken-3" class="mr-1 white--text" @click="setDateRange('week-1')">Poprzedni tydzień</v-btn>
                    <v-btn x-small color="green darken-3" class="mr-1 white--text" @click="setDateRange('week')">Aktualny tydzień</v-btn>
                    <v-btn x-small color="green darken-2" class="mr-1 white--text" @click="setDateRange('yesterday')">Wczoraj</v-btn>
                    <v-btn x-small color="green darken-2" class="mr-1 white--text" @click="setDateRange('today')">Dzisiaj</v-btn>
                    <v-btn outlined @click="resetSearch" class="ml-auto">RESETUJ</v-btn>
                    <v-btn color="primary" tile type="submit"><v-icon class="mr-1">mdi-magnify</v-icon> SZUKAJ</v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <template v-if="loaded">
            <v-card tile class="px-3 mb-3">
                <v-card-title class="d-flex align-center w-100 text-uppercase" v-if="driverID && statistics.per_driver[0].driver_data">
                    Statystyki dla <v-chip class="px-2 mx-2" color="primary" label v-if="statistics.per_driver[0].driver_data && statistics.per_driver[0].driver_data.driver_number">{{ statistics.per_driver[0].driver_data.driver_number }}</v-chip>
                    <template v-if="statistics.per_driver[0].driver_data && statistics.per_driver[0].driver_data.first_name">{{ statistics.per_driver[0].driver_data.first_name }} </template>
                    <template v-if="statistics.per_driver[0].driver_data && statistics.per_driver[0].driver_data.last_name">{{ statistics.per_driver[0].driver_data.last_name }}</template>
                    <v-chip class="ml-auto" dark small label v-if="statistics.per_driver[0].total">Ilość zleceń: <strong class="ml-2">{{ statistics.per_driver[0].total }}</strong></v-chip>
                </v-card-title>
                <v-card-title class="d-flex align-center w-100 text-uppercase" v-else>
                    Statystyki
                    <v-chip class="ml-auto" dark small label>Ilość zleceń: <strong class="ml-2">{{ statistics.total }}</strong></v-chip>
                </v-card-title>
                <v-card-text class="pb-5">
                    <h3 class="my-3">Na dzień</h3>
                    <column-chart
                        :data="statistics.per_day"
                    />
                </v-card-text>
                <v-card-text class="pb-5">
                    <h3 class="my-3">Na godzinę</h3>
                    <column-chart
                        :data="statistics.per_hour"
                    />
                </v-card-text>
            </v-card>

            <template v-if="!driverID">
                <v-card tile class="px-3 mb-3">
                    <v-card-title class="d-flex align-center w-100 text-uppercase">
                        Statystyki z podziałem na kierwoców
                    </v-card-title>
                    <v-card-text class="pb-5" v-if="statistics.per_driver">
                        <v-list class="list-per-driver">
                            <v-list-item-group>
                                <v-list-item
                                    v-for="(driver, index) in statistics.per_driver"
                                    :key="index"
                                    @click="setDriver(driver.driver_data.id)"
                                >
                                    <v-chip
                                        color="primary"
                                        small
                                        label
                                        class="mr-3"
                                    >{{ driver.driver_data.driver_number }}</v-chip>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ driver.driver_data.first_name }} {{ driver.driver_data.last_name }}</v-list-item-title>
                                    </v-list-item-content>
                                    <div class="ml-auto">
                                        <v-chip  dark small label>
                                            {{ driver.total }}
                                        </v-chip>
                                    </div>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card-text>
                </v-card>
            </template>
        </template>
        <div class="text-center py-5 my-5" v-else>
            <v-icon large class="mr-2">mdi-loading mdi-spin</v-icon> Trwa ładowanie danych...
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js/dist/Chart'
import 'moment/moment';
import 'moment/locale/pl';
import 'chartjs-adapter-moment';

Vue.use(Chartkick.use(Chart))

export default {
    name: 'Statistics',
    props: {
        listDriver: {
            type: Array,
            required: true
        }
    },
    data: () => ({
        perDay: {},
        perHour: {},
        searchDateFrom: null,
        searchDateTo: null,
        driverID: null,
        statistics: {
            params: {},
            per_day: {},
            per_driver: {},
            per_hour: {},
            total: 0,
        },
        loaded: false
    }),
    mounted () {
        this.setDateRange('month');
    },
    methods: {
        setDriver(driver_id) {
            this.driverID = driver_id
            this.getDataFromApi();
        },
        setDateRange(type) {
            switch (type) {
                case 'today':
                    this.searchDateFrom = this.$moment().startOf('day').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().endOf('day').format('YYYY-MM-DD HH:mm');
                    break;
                case 'yesterday':
                    this.searchDateFrom = this.$moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm');
                    break;
                case 'week':
                    this.searchDateFrom = this.$moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    break;
                case 'week-1':
                    this.searchDateFrom = this.$moment().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD HH:mm');
                    break;
                case 'month':
                    this.searchDateFrom = this.$moment().startOf('month').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().endOf('month').format('YYYY-MM-DD HH:mm');
                    break;
                case 'month-1':
                    this.searchDateFrom = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm');
                    break;
            }
            this.getDataFromApi();
        },
        getDataFromApi() {
            this.loaded = false
            this.$http.get('statistics/get', {
                params: {
                    date_from: this.searchDateFrom,
                    date_to: this.searchDateTo,
                    driver_id: this.driverID,
                }
            })
                .then((response) => {
                    this.statistics = {
                        params: {},
                        per_day: {},
                        per_driver: {},
                        per_hour: {},
                        total: 0,
                    };
                    this.statistics = response.data
                    this.searchDateFrom = this.$moment(response.data.params.timestamp_from * 1000).format('YYYY-MM-DD HH:mm');
                    this.searchDateTo = this.$moment(response.data.params.timestamp_to * 1000).format('YYYY-MM-DD HH:mm');
                    this.driverID = response.data.params.driver_od;
                    this.loaded = true
                })
                .catch(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'error',
                        title: 'Wystąpił błąd.',
                        text: 'Podczas pobierania statystyk.'
                    });
                });
        },
        resetFieldSearch() {
            this.searchDateFrom = '';
            this.searchDateTo = '';
            this.driverID = null;
        },
        resetSearch () {
            this.resetFieldSearch();
            this.getDataFromApi();
        },
    }
}
</script>

<style lang="scss">
.list-per-driver .v-list-item:hover {
    background-color: yellow;
    .v-list-item__content {
        color: #000;
    }
}
</style>
